.wrapper {
    @apply block overflow-x-auto;

    .projectTable {
        width: 100%;
        max-width: 1040px;
        margin: 0 auto 20px;
        background: #e1e1a9;
    }

    > table {
        @apply w-full;
        border-left: 1px solid #999964;
        border-top: 1px solid #999964;
        color: #803e01;

        th {
            @apply uppercase text-center font-bold;

            &:first-child {
                @apply text-left;
            }
        }

        th, td {
            @apply p-2;
            box-sizing: border-box;
            border-right: 1px solid #999964;
            border-bottom: 1px solid #999964;

            &.noPadding {
                @apply p-0;
            }

            &.productStatus {
                @apply relative;

                a,
                div {
                    @apply absolute top-0 left-0 flex items-center justify-center w-full h-full;
                }
            }

            a:hover {
                color: #c25d00;
            }
        }

        th {
            @apply py-1 text-sm;
        }

        tr:first-child {
            background: #999964;
            color: #e1e1a9;
        }

        .statusCol {
            width: 180px;
        }

        > tr {

            td {
                // @apply border border-white p-1 text-center font-semibold w-20;

                .complete {
                    background: #4f772d;

                    path {
                        fill: #86c84e;
                    }

                    &:hover {
                        background: #6ba23c;

                        path {
                            fill: #86c84e;
                        }
                    }
                }

                .inprogress {
                    background: #FFB731;

                    path {
                        fill: #FFc75f;
                    }

                    &:hover {
                        background: #FFc75f;

                        path {
                            fill: #FFB731;
                        }
                    }
                }

                .behind {
                    background: #f46231;

                    path {
                        fill: #ffd1a6;
                    }

                    &:hover {
                        background: #ff845a;

                        path {
                            fill: #ffd1a6;
                        }
                    }
                }

                .notstarted {
                    background: #c8c887;

                    path {
                        fill: #d8d895;
                    }

                    &:hover {
                        background: #c8c887;

                        path {
                            fill: #d8d895;
                        }
                    }
                }
            }
        }
    }
}
